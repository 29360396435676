// 设备分类接口
import {
	axios
} from '../utils/http.js'
const DeviceApi = {
	UserList: `/User/List`, //公司列表
	UserAdd: `/User/Add`, //添加公司
	UserUp: `/User/Up`, //隐藏、显示
	TaskList: `/Task/List`, //任务管理列表
	TaskAdd: `/Task/Add`, //添加任务管理
	TaskUp: `/Task/Up`, //显示隐藏
	TaskaddData:`Task/AddData_Tool`,
	DeviceClassList: `/DeviceClass/List`, //首页点击数据来源进入的列表
	TaskDataExtract_TaskData: `/TaskData/Extract_TaskData`, //首页点击数据来源-数据检验进入时间选择
	TaskDataList: `/TaskData/List`, //首页点击数据来源-数据检验进入时间选择-调取列表
}

//数据编辑
const TaskDataApi = {
	
	TaskDataClassList: `/TaskData/TaskDataClass_List`, //数据编辑导航菜单列表  //获取测点通接口
	TaskDataEchartsList: `/TaskData/List`, //数据编辑Echarts列表
	TaskDataAdd: `/TaskData/Add`, //添加数据
	ExcAdd:'/TaskData/AddS',
	TaskDataUp: `/TaskData/Up`, //修改图表某一点数据
	TaskDataDel: `/TaskData/Del`, //删除图表某一点数据
	DelTid:`/TaskData/DelTid`,
	TaskDataExcel: `/TaskData/Export_Data_Excel`, //删除图表某一点数据
	TaskDataColdVerify: `/Data/Device_Sensor_Data_More_ColdVerify/Data/Device_Sensor_Data_More_ColdVerify`, //导入数据（冷链平台）
	
}


//数据校验
const DataCheckApi = {
	TaskCheckList: `/TaskData/Check`, //数据校验列表
	TaskCheckTaskDataClass: `/TaskData/TaskDataClass_List`, //单选多选数据
}

//报告生成
const VerifyTempApi = {
	VerifyTemplateList: `/VerifyTemplate/List`, //验证模板拿数据
	VerifyTemplateMap_List: `/VerifyTemplate/Map_List`, //验证模板：之后调取数据
	GenerateReports: `/GenerateReport`, //生成报告
	GenerateReport_Key : `GenerateReport_Key`
}
//报告编辑
const TaskGetsApi = {
	TaskGet: `/Task/Get`, //报告编辑
}

export function TaskaddData(data) {
	return axios({
		url: DeviceApi.TaskaddData,
		method: 'post',
		data: data
	})
}
//、、、、、、、、、、、、、、、、、、、、、、、、数据编辑、、、、、、、、、、、、、、、、、
export function TaskDataClassList(data) {
	return axios({
		url: TaskDataApi.TaskDataClassList,
		method: 'post',
		data: data
	})
}

export function TaskDataEchartsList(data) {
	return axios({
		url: TaskDataApi.TaskDataEchartsList,
		method: 'post',
		data: data
	})
}

export function TaskDataAdd(data) {//添加数据
	return axios({
		url: TaskDataApi.TaskDataAdd,
		method: 'post',
		data: data
	})
}
export function ExcAdd(data) {//添加数据
	return axios({
		url: TaskDataApi.ExcAdd,
		method: 'post',
		data: data
	})
}



export function TaskDataColdVerify(data) {//添加数据
	return axios({
		url: TaskDataApi.TaskDataColdVerify,
		method: 'post',
		data: data
	})
}

export function TaskDataUp(data) {//添加数据
	return axios({
		url: TaskDataApi.TaskDataUp,
		method: 'post',
		data: data
	})
}
export function TaskDataDel(data) {//删除当前点的数据
	return axios({
		url: TaskDataApi.TaskDataDel,
		method: 'post',
		data: data
	})
}
export function DelTid(data) {//删除当前点的数据
	return axios({
		url: TaskDataApi.DelTid,
		method: 'post',
		data: data
	})
}

export function TaskDataExcel(data) {//导出图表数据
	return axios({
		url: TaskDataApi.TaskDataExcel,
		method: 'post',
		data: data
	})
}

//设备分类列表
export function UserList(data) {
	return axios({
		url: DeviceApi.UserList,
		method: 'post',
		data: data
	})
}

//设备分类列表
export function UserAdd(data) {
	return axios({
		url: DeviceApi.UserAdd,
		method: 'post',
		data: data
	})
}
//隐藏、显示
export function UserUp(data) {
	return axios({
		url: DeviceApi.UserUp,
		method: 'post',
		data: data
	})
}
//任务管理列表
export function TaskList(data) {
	return axios({
		url: DeviceApi.TaskList,
		method: 'post',
		data: data
	})
}
//添加任务管理
export function TaskAdd(data) {
	return axios({
		url: DeviceApi.TaskAdd,
		method: 'post',
		data: data
	})
}
//显示隐藏
export function TaskUp(data) {
	return axios({
		url: DeviceApi.TaskUp,
		method: 'post',
		data: data
	})
}
//首页点击数据来源进入的列表
export function DeviceClassList(data) {
	return axios({
		url: DeviceApi.DeviceClassList,
		method: 'post',
		data: data
	})
}
//首页点击数据来源-数据检验进入时间选择TaskDataExtract_TaskData
export function TaskDataExtract_TaskData(data) {
	return axios({
		url: DeviceApi.TaskDataExtract_TaskData,
		method: 'post',
		data: data
	})
}
//首页点击数据来源-数据检验进入时间选择TaskDataExtract_TaskData
export function TaskDataList(data) {
	return axios({
		url: DeviceApi.TaskDataList,
		method: 'post',
		data: data
	})
}

////数据校验列表
export function TaskCheckList(data) {
	return axios({
		url: DataCheckApi.TaskCheckList,
		method: 'post',
		data: data
	})
}
////数据校验列表
export function TaskCheckTaskDataClass(data) {
	return axios({
		url: DataCheckApi.TaskCheckTaskDataClass,
		method: 'post',
		data: data
	})
}
//、、、、、、、、、、、、、、、、、、、、、、、、数据校验列表、、、、、、、、、、、、、、、、、
////验证模板：
export function VerifyTemplateList(data) {
	return axios({
		url: VerifyTempApi.VerifyTemplateList,
		method: 'post',
		data: data
	})
}

////验证模板：之后调取数据
export function VerifyTemplateMap_List(data) {
	return axios({
		url: VerifyTempApi.VerifyTemplateMap_List,
		method: 'post',
		data: data
	})
}
//生成报告
export function GenerateReports(data) {
	return axios({
		url: VerifyTempApi.GenerateReports,
		method: 'post',
		data: data
	})
}
//生成报告
export function GenerateReport_Key(data) {
	return axios({
		url: VerifyTempApi.GenerateReport_Key,
		method: 'post',
		data: data
	})
}

//、、、、、、、、、、、、、、、、、、、、、、、、报告编辑、、、、、、、、、、、、、、、、、
//下载docx
export function TaskGetDocx(data) {
	return axios({
		url: TaskGetsApi.TaskGet,
		method: 'post',
		data: data
	})
}



