<template>
	<!-- 数据来源 -->
	<div class="DataSources">
		<div class="DataSources1">
			<div class="DataSources1_1">
				<div style="margin-right: 20px;">
					<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入SN搜索" v-model="Taskfrom.T_sn"
						clearable @keyup.enter.native="searchValue"></el-input>
				</div>
				<div style="margin-right: 20px;">
					<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入设备编号搜索" v-model="Taskfrom.T_id"
						clearable @keyup.enter.native="searchValue"></el-input>
				</div>
				<div style="margin-right: 10px;">
					<el-date-picker v-model="value2" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" @change="searchTimeData">
					</el-date-picker>
				</div>
				<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;" @click="searchValue()">搜索
				</el-button>
				<el-button type="danger" icon="el-icon-search" style="margin: 0 10px;" @click="ResetData()">重置数据
				</el-button>
			</div>
		</div>
		<div class="DataSources2">
			<el-table :data="tableData" style="width: 100%" :border='true'>
				<el-table-column prop="ID" label="ID" width="100">
				</el-table-column>
				<el-table-column prop="T_id" label="设备编号">
				</el-table-column>
				<el-table-column prop="T_sn" label="SN">
				</el-table-column>
				<el-table-column prop="T_t" label="温度(℃)">
				</el-table-column>
				<el-table-column prop="T_rh" label="湿度(%)">
				</el-table-column>
				<el-table-column prop="T_time" label="记录时间">
				</el-table-column>
			</el-table>
		</div>
		<div class="DataSources3">
			<el-drawer title="设备数据来源:" :visible.sync="drawer" direction="rtl" :show-close="false" size="50%"
				custom-class="demo-drawer" ref="drawer">
				<div class="demo-drawer__content" style="margin: 20px;">
					<div class="DataSources31">
						<div class="DataSources31_1">
							<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入公司名称搜索"
								v-model="Pages.T_name" clearable @keyup.enter.native="searchTan"></el-input>
							<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;"
								@click="searchTan()">搜索
							</el-button>
						</div>
					</div>
					<div class="DataSources32">
						<el-table :data="gridData" :border="true">
							<el-table-column property="T_name" label="名称"></el-table-column>
							<el-table-column property="address" label="操作">
								<template slot-scope="scope">
									<el-button type="primary" size="small" @click="showinnerDrawer(scope.row)">选择分类
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="DataSources33">
						<el-pagination background @current-change="CurrentChange" :page-size="Pages.page_z"
							layout="total, prev, pager, next, jumper" :total="Pagestotal">
						</el-pagination>
					</div>
				</div>
				<el-drawer title="数据检验:" :append-to-body="true" size="35%" :show-close="false"
					:visible.sync="innerDrawer">
					<div style="margin: 20px;display: flex;align-items: center">
						<el-date-picker v-model="value1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" @change="TimeData"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<div style="margin-left: 20px;">
							<el-button type="primary" icon="el-icon-search" @click="GetSources">导入数据</el-button>
						</div>
					</div>
				</el-drawer>
			</el-drawer>
		</div>
		<div class="DataSources4">
			<el-pagination background @current-change="handleCurrentChange" :page-size="Taskfrom.page_z"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {
		DeviceClassList,
		TaskDataExtract_TaskData,
		TaskDataList
	} from '../../../../api/CorpManage.js'
	export default {
		data() {
			return {
				search: '',
				value1: null,
				value2: null,
				drawer: false, //数据来源进入的弹窗
				innerDrawer: false, //数据来源进入的弹窗-时间选择
				// quanxian:false,
				gridData: [],
				InnerData: {
					T_task_id: this.$store.state.T_task_id.T_task_id,
					T_Class_id: this.$store.state.innerDrawerID.Id,
					Time_start: '',
					Time_end: ''
				},
				Taskfrom: {
					T_task_id: this.$store.state.T_task_id.T_task_id,
					T_sn: null, //sn搜索
					T_id: null, //id搜索
					Time_start: null, //开始时间
					Time_end: null, //结束时间
					page: 1,
					page_z: 10,
				},
				tableData: [],
				Pages: {
					page: 1,
					page_z: 8,
					T_name: null
				},
				Pagestotal: 0, //总条数
				total: 0
			}
		},
		mounted() {
			this.PostTaskDataList()
		},
		methods: {
			PostTaskDataList() {//表格数据
				TaskDataList(this.Taskfrom).then(res => {
					if (res.data.Code === 200) {
						this.total = res.data.Data.Num
						if(this.total===0){
							this.drawer = true
							this.PostDeviceClassList()
						}else{
							// this.quanxian = true
							this.tableData = res.data.Data.List
						}
					}
				})
			},
			PostDeviceClassList() {//一级弹出窗口选择
				DeviceClassList(this.Pages).then(res => {
					this.gridData = []
					if (res.data.Code == 200) {
						this.gridData = res.data.Data.List
						this.Pagestotal = res.data.Data.Num
					}
				})
			},
			showinnerDrawer(e) { //一级弹出窗口选择-点击发送检验
				this.innerDrawer = true  //二级窗口显示
				this.$store.commit('SetinnerDrawerID', e)
			},
			PostTaskDataExt() {
				this.InnerData.T_Class_id = this.$store.state.innerDrawerID.Id
				TaskDataExtract_TaskData(this.InnerData).then(res => {
					if (res.data.Code === 200) {
						console.log('200')
						this.PostTaskDataList()
						// this.quanxian = true
						// console.log('赋值',this.quanxian)
					} else if (res.data.Code === 201) {
						console.log('201')
						this.$router.replace({
							path: '/corpManageIndex'
						})
					}
				})
			},
			ResetData() { //重置数据
				this.$confirm('此操作将重置数据, 是否继续?', '重置提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					showClose: false
				}).then(() => {
					this.drawer = true
					this.Taskfrom.page = 1
					this.PostDeviceClassList()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消重置数据'
					});
				});
			},
			TimeData() {
				console.log('time1', this.value1)
				if(this.value1==null){
					this.value1 = []
					this.InnerData.Time_start = ''
					this.InnerData.Time_end = ''
				}else{
					this.InnerData.Time_start = this.value1[0]
					this.InnerData.Time_end = this.value1[1]
				}
			},
			searchTimeData() {
				if(this.value2==null){
					this.value2 = []
					this.Taskfrom.Time_start = ''
					this.Taskfrom.Time_end = ''
				}else{
					this.Taskfrom.Time_start = this.value2[0]
					this.Taskfrom.Time_end = this.value2[1]
				}
			},
			GetSources() {
				if (this.value1 == null) {
					this.$message({
						message: '检验时间不能设置为空',
						type: 'error'
					});
				} else {
					this.innerDrawer = false //数据来源进入的弹窗-时间选择
					this.drawer = false //数据来源进入的弹窗
					this.PostTaskDataExt()
				}
			},
			searchTan() { //搜索
				this.Taskfrom.page = 1
				this.PostDeviceClassList()
			},
			searchValue() { //搜索
				this.Taskfrom.page = 1
				this.PostTaskDataList()
			},
			CurrentChange(val) { //弹窗当前页
				this.Pages.page = val
				this.PostDeviceClassList()
			},
			handleCurrentChange(val) { //列表当前页
				this.Taskfrom.page = val
				this.PostTaskDataList()
			},
		}
	}
</script>

<style>
	.DataSources {
		background: #fff;
		padding: 10px;
	}

	.DataSources1 {
		display: flex;
		align-items: center;
	}

	.DataSources1_1 {
		display: flex;
		align-items: center;
		min-width: 500px;
	}

	.DataSources2 {
		margin-top: 20px;
	}

	.DataSources2_mai {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 20px;
	}

	.DataSources2_mai1 {
		border: 1px solid #f1f1ff;
		box-shadow: 0 1px 2px 0 #f1f1ff;
		border-radius: 5px;
		overflow: hidden;
	}

	.DataSources2_mai1_img {
		width: 100%;
		height: 70px;
	}

	.DataSources2_mai1_tit {
		margin: 10px;
		font-size: 15px;
		color: #303133;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.DataSources2_mai1_link {
		text-align: right;
		padding: 10px;
	}

	.DataSources31 {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.DataSources31_1 {
		display: flex;
		align-items: center;
		min-width: 500px;
	}

	.DataSources32 {
		margin-bottom: 20px;
	}

	.DataSources4 {
		margin-top: 30px;
	}
</style>
